document.addEventListener("DOMContentLoaded", function () {
	$(document).ready(function () {
		// setting Help icon to navbar Helpdesk button
		$('.dropdown-toggle').html('<svg fill="#000000" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 6a3.939 3.939 0 0 0-3.934 3.934h2C10.066 8.867 10.934 8 12 8s1.934.867 1.934 1.934c0 .598-.481 1.032-1.216 1.626a9.208 9.208 0 0 0-.691.599c-.998.997-1.027 2.056-1.027 2.174V15h2l-.001-.633c.001-.016.033-.386.441-.793.15-.15.339-.3.535-.458.779-.631 1.958-1.584 1.958-3.182A3.937 3.937 0 0 0 12 6zm-1 10h2v2h-2z" fill="#000" style=""></path><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z" fill="#000" style=""></path></svg>');
		const path = window.location.pathname;
		if (path === "/trade-term-contract/list") {
			setup_tooltip();
			const listContainer = $(".web-list-container");
			if (listContainer.length > 0) {
				const tabs = ["Action Needed", "Pending", "Completed", "Expired", "Rejected"];
				const newItem = $("<div>", { class: "rebate-contract-filters" });
				createTabs(tabs, newItem, "#workflow_state");
				listContainer.children().eq(0).after(newItem);
			}
		}
		// For Cost Update Web view
		else if (path === "/sku-request-change/list") {
			$(".page_content").css({ "max-width": "none" });
			$(".web-list-header").hide();
			setTimeout(() => {
				$('input[data-fieldname="cost_update_request"]')
					.val(JSON.parse(localStorage.getItem("cost_update_requests")))
					.trigger("change");
			}, 500);
		} else if (path === "/cost-update-request/list") {
			$(".page_content").css({ "max-width": "none" });
			$(".web-list-header").hide();
			const listContainer = $(".web-list-container");
			createActions(listContainer);
			setupCheckboxListener();
		}
		setTimeout(() => {
			if (path === "/trade-term-contract/list") {
				// Rearrange the filter fields based on the new order
				["supplier_name", "supplier_id", "trade_terms_type", "start_date", "end_date"].forEach(
					(fieldName) => {
						const $filter = $(`.web-list-filters .form-group[data-fieldname='${fieldName}']`);
						$(".web-list-filters").append($filter);
					}
				);
				addPlaceHolderToSelectField("trade_terms_type", "Trade Term Type");
				addPlaceHolderToSelectField("vendor_contract_status", "Vendor Contract Status");
				$(".rebate-contract-filters").find("button:first").trigger("click");
			}
		}, 500);
	});
});

function setup_tooltip() {
	setTimeout(() => {
		// Hide the "Sr." column header
		$('th:contains("Sr.")').hide();

		// Hide the cells in the "Sr." column
		$("td.list-col-serial").hide();
		// Select all table rows within the web-list-table class
		var rows = document.querySelectorAll(".web-list-table tbody tr");

		if ($(".web-list-table thead tr").find(".th_action").length <= 0) {
			$(".web-list-table thead tr").append('<th class="th_action"></th>');
		}

		rows.forEach(function (row) {
			// Get the row ID
			var rowId = row.id;

			let link_pdf = `/printview?doctype=Trade Term Contract&name=${rowId}&format=${encodeURIComponent(
				"Direct or Distributor or Drop ship"
			)}&no_letterhead=0&letterhead=Chewy&settings=%7B%7D&_lang=en`;

			if ($(row).find(".td_action").length <= 0) {
				$(row)
					.append(`<td class="td_action"><a href="${link_pdf}" target="_blank" class="btn btn-action btn-default btn-xs" title="Show PDF ${rowId}">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path d="M8 2c1.981 0 3.671.992 4.933 2.078 1.27 1.091 2.187 2.345 2.637 3.023a1.62 1.62 0 0 1 0 1.798c-.45.678-1.367 1.932-2.637 3.023C11.67 13.008 9.981 14 8 14c-1.981 0-3.671-.992-4.933-2.078C1.797 10.83.88 9.576.43 8.898a1.62 1.62 0 0 1 0-1.798c.45-.677 1.367-1.931 2.637-3.022C4.33 2.992 6.019 2 8 2ZM1.679 7.932a.12.12 0 0 0 0 .136c.411.622 1.241 1.75 2.366 2.717C5.176 11.758 6.527 12.5 8 12.5c1.473 0 2.825-.742 3.955-1.715 1.124-.967 1.954-2.096 2.366-2.717a.12.12 0 0 0 0-.136c-.412-.621-1.242-1.75-2.366-2.717C10.824 4.242 9.473 3.5 8 3.5c-1.473 0-2.825.742-3.955 1.715-1.124.967-1.954 2.096-2.366 2.717ZM8 10a2 2 0 1 1-.001-3.999A2 2 0 0 1 8 10Z"></path></svg>
				</a></td>`);
			}

			// Select all ellipsis elements within the current row
			var ellipsisElements = row.querySelectorAll(".ellipsis");

			// Add title attribute to each ellipsis element with the row ID
			ellipsisElements.forEach(function (element) {
				element.setAttribute("title", rowId);
			});
		});
		// onlcick it renders more records so to add tooltip to that records aswell  adding this listener to Load More button
		$(".more").click(() => {
			setup_tooltip();
		});
	}, 1000);
}

function createTabs(tabs, container, targetField) {
	const buttons = tabs.map((text) => $("<button>").text(text));
	container.html(buttons);

	container.on("click", "button", function () {
		setup_tooltip();
		const tabText = $(this).text().toLowerCase().replace(" ", "_");
		$(this).addClass("selected").siblings().removeClass("selected");
		$(targetField).val(tabText);
		const dropdown = $(`[data-fieldname="${targetField.substring(1)}"] select`);
		dropdown.val(tabText).trigger("change");
	});

	$(document).on("change", `[data-fieldname="${targetField.substring(1)}"] select`, function () {
		const selectedValue = $(this).val();
		const tab = container.find("button").filter(function () {
			return $(this).text().toLowerCase().replace(" ", "_") === selectedValue;
		});
		tab.addClass("selected").siblings().removeClass("selected");
	});
}

function addPlaceHolderToSelectField(fieldName, placeholderText) {
	let $selectField = $(`select[data-fieldname="${fieldName}"]`);
	if ($selectField.length) {
		$selectField.prepend(`<option value="" disabled selected>${placeholderText}</option>`);
	}
}

function createActions(listContainer) {
	let html = `
		<div id="actions-container">
			<button class="tab-button" id="request-cost-update" style="color:grey; background-color: transparent; border: none; cursor: pointer;">Request Cost Update</button>
			<button class="tab-button" id="cost-update-history" style="color:grey; background-color: transparent; border: none; cursor: pointer;">Cost Update History</button>
			<!-- Dropdown for Cost Update History -->
			<div class="dropdown-cu" style="display:none; float: right;">
				<button>Actions</button>
				<div class="dropdown-cu-content">
					<button>Download</button>
					<button>Cancel Request</button>
				</div>
			</div>
		</div>
	`;

	listContainer.children().eq(0).after(html);

	// jQuery to handle button activation and dropdown functionality
	$("#actions-container > button.tab-button").on("click", function () {
		// Remove active state from all buttons
		$("#actions-container > button.tab-button").css({ color: "grey" });
		// Add active state to the clicked button
		$(this).css({ color: "black" });
	});

	// Detect clicks outside of #actions-container
	$(document).on("click", function (event) {
		// Check if the clicked target is outside the #actions-container
		if (!$(event.target).closest("#actions-container > button.tab-button").length) {
			// Reset button styles and hide the dropdown
			$("#actions-container > button.tab-button").css({ color: "grey" });
		}
	});
}

function setupCheckboxListener() {
	setTimeout(function () {
		// Select all row checkboxes
		const checkboxes = document.querySelectorAll('input[type="checkbox"]');

		checkboxes.forEach((checkbox) => {
			checkbox.addEventListener("change", handleSelectionChange);
		});
	}, 2000);
}

function handleSelectionChange() {
	// Get all selected rows
	let selectedRows = [];
	const selectedCheckboxes = document.querySelectorAll(
		'.list-col-checkbox input[type="checkbox"]:checked'
	);

	selectedCheckboxes.forEach((checkbox) => {
		// Get the parent row ID (from the <tr id="...">)
		let row = checkbox.closest("tr");
		let recordId = row.getAttribute("id");
		selectedRows.push(recordId);
	});

	// Run your custom function when records are selected
	processSelectedRecords(selectedRows);
}

function processSelectedRecords(selectedRecords) {
	if (selectedRecords.length > 0) {
		$(".dropdown-cu").css({ display: "block" });
	} else {
		$(".dropdown-cu").css({ display: "none" });
	}
}
